import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Api from '../Lib/Api';

const Component = (props) => {

  useEffect(() => {
    props.healthCheck();
  }, [])

  return (
    <main>
      <section>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <h2 className="mt-50">Health Check</h2>
              <div className="authentication-form">
                <ul>
                  <li>Environment: { props.health.env || 'FAIL' }</li>
                  <li>Version: { props.health.version || 'FAIL' }</li>
                  <li>API: { props.health.api ? 'OK' : 'FAIL' }</li>
                  <li>Database: { props.health.database ? 'OK' : 'FAIL' }</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
    health: state.health,
});
const mapDispatchToProps = {
  healthCheck: Api.healthCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
