import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from 'react-redux';
import {Helmet} from "react-helmet";
import { ToastContainer } from 'react-toastify';

import pkg from '../package.json';

import Navigation from './Components/Navigation';
import Reports from './Containers/Reports';
import Organizations from './Containers/Organizations';
import Requests from './Containers/Requests';
import Inventory from './Containers/Inventory';
import Login from './Containers/Login';
import AdminPanel from './Containers/AdminPanel';
import Messaging from './Containers/Messaging';
import Expired from './Containers/Report/Expired';
import HealthCheck from './Containers/HealthCheck';

import ErrorBoundary from './Components/ErrorBoundary';
import Auth from './Lib/Auth';
import Store from './Lib/Store';
import ApiRetriever from './Components/ApiRetriever';
import ForgotPassword from './Containers/ForgotPassword';
import Distribution from './Containers/Distribution';
import AdminNotes from './Containers/Report/AdminNotes';
import LocationList from './Containers/LocationList';
import ItemChangeList from './Containers/ItemChangeList';
import { toast } from 'react-toastify';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.isLoggedIn() === true
    // || true // comment out to get past auth
      ? <Component {...props} />
      : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
  )}
  />
);

export default class Application extends React.Component {
  componentDidMount() {
    const options = JSON.parse(localStorage.getItem('options') || '{}');
    if (options.kiosk) {
      toast.info('Kiosk Mode Enabled', {autoClose:1000});
    }
  }

  render() {
    return (
      <Provider store={Store}>
        <Helmet>
          <title>SCMNFR</title>
        </Helmet>
        <Router>
          <ErrorBoundary>
            <Navigation />
            <ApiRetriever />
            <div className="app">
              <Switch>
                <Route path="/health-check" component={HealthCheck} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute path="/reports/locations" component={LocationList} />
                <PrivateRoute path="/reports/admin-notes" component={AdminNotes} />
                <PrivateRoute path="/reports/expired" component={Expired} />
                
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/organizations" component={Organizations} />
                <PrivateRoute path="/history" component={Requests} />
                <PrivateRoute path="/admin" component={AdminPanel} />
                <PrivateRoute path="/item-changes" component={ItemChangeList} />
                <PrivateRoute path="/distribution" component={Distribution} />
                <PrivateRoute path="/messaging" component={Messaging} />
                <PrivateRoute path="/" component={Inventory} />
              </Switch>
            </div>
          </ErrorBoundary>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <div className="simple-container">&copy; {(new Date().getFullYear())} Wooden Spoon</div>
          <div className={`version-footer ${process.env.BRANCH === 'main' ? 'hide' : ''}`}>v{pkg.version} {(process.env.BRANCH || 'development')}</div>
        </Router>
      </Provider>
    );
  }
}
